<template src="./EditFormCourse.html"></template>

<script>
import coursesService from "@/services/Courses";
import SearchIcon from "@/components/Icons/SearchIcon";
import locationService from "../../services/Locations";

export default {
  name: "EditFormCourse",
  data() {
    return {
      step: 1,
      coursesSelected: [],
      AllCoursesByInstitution: [],
      wordForSearchCourse: null,
      selectedCourse: null,
      age: {},
      location: {},
      capabilities: {},
      other: {},
      allGeneralCourses: [],
      arrayCourseToShow: [],
      ageOptions: [
        {
          name: "Rango",
          key: "range",
        },
        {
          name: "Edad especifica",
          key: "specificAge",
        },
      ],
      stateOptions: [],
      citiesOptions: [],
      communeOptions: [],
      ageSelection: {
        name: "Rango",
        key: "range",
      },
      stateSelection: null,
      specificAge: null,
      coursesList: [
        {
          name: "nombre del curso",
          id: 1,
        },
        {
          name: "nombre del curso",
          id: 2,
        },
        {
          name: "nombre del curso",
          id: 3,
        },
        {
          name: "nombre del curso",
          id: 4,
        },
        {
          name: "nombre del curso",
          id: 4,
        },
      ],
      answerType: [
        {
          name: "Respuesta de texto corta",
          key: "antioquia",
        },
        {
          name: "Respuesta de opción única",
          key: "antioquia",
        },
        {
          name: "Respuesta alfanumerica",
          key: "antioquia",
        },
      ],
      inputTypeOptions: [
        {
          key: "Respuesta de texto corta",
          value: "shortText",
        },
        {
          key: "Respuesta de texto largo",
          value: "textLong",
        },
        {
          key: "Respuesta de opción única",
          value: "select",
        },
        {
          key: "Respuesta de opción multiple",
          value: "multiSelect",
        },
        {
          key: "Respuesta alfanumerica",
          value: "number",
        },
        {
          key: "Fecha",
          value: "date",
        },
        {
          key: "Dirección",
          value: "addresses",
        },
        {
          key: "Correo",
          value: "mail",
        },
        {
          key: "Ubicación",
          value: "location",
        },
      ],
      newCapability: "",
      requirementData: {},
      dataStructure: {},
      take: 10,
      skip: 0,
    };
  },
  components: {
    SearchIcon,
  },
  methods: {
    async loadMoreCourses() {
      this.take += 10;
      this.skip += 10;
      const moreCourses = await coursesService.getContinuosEducationCourses(this.take, this.skip);
      this.allGeneralCourses = this.allGeneralCourses.concat(moreCourses);
      this.courseToShow();
      await this.$store.dispatch('fetchLoading', false);
    },
    async getAllGeneralCourses() {
      try {
        this.allGeneralCourses = await coursesService.getContinuosEducationCourses(this.take, this.skip);
        this.courseToShow();
      } catch (e) {
        console.log("e", e);
      }
    },
    async getCourseFormUserEnrolmentCourse(id) {
      try {
        const response =
          await coursesService.getCourseFormUserEnrolmentCourseByFormUserEnrolment(
            id
          );
        this.coursesSelected = response.map((course) => course.courseId);
        console.log("coursesSelected", this.coursesSelected);
      } catch (e) {
        console.log("e", e);
      }
    },
    async getCourseFormInformation() {
      try {
        this.dataStructure = await coursesService.getFormCourseInformation(
          this.$route.params.courseId
        );
        console.log("this.dataStructure", this.dataStructure);
        this.getCourseFormUserEnrolmentCourse(this.dataStructure.id);
        await this.getRequirementData();
      } catch (e) {
        console.log("e", e);
      }
    },
    async editCourseForm() {
      try {
        await this.assingRequirementData();
        Reflect.deleteProperty(this.dataStructure, "id");
        await coursesService.editCourseForm(
          this.$route.params.courseId,
          this.dataStructure
        );
        this.coursesSelected.map(async (element) => {
          await coursesService.linkEnrollFormWithCourse({
            formEnrollCourseId: this.$route.params.courseId,
            courseId: element,
          });
        });
        await this.$router.push({ name: "AllFormsAcademic" });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Formulario del curso actualizado correctamente",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (e) {
        console.log("e", e);
      }
    },
    deleteQuestion(node, index) {
      this.dataStructure[node].splice(index, 1);
    },
    deleteQuestionOption(node, indexQuestion, indexOption) {
      this.dataStructure[node][indexQuestion].answers.splice(indexOption, 1);
    },
    addQuestion(node) {
      this.dataStructure[node].push({
        inputType: "number",
        required: false,
        question: "question",
      });
    },
    addQuestionOption(node, indexQuestion) {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.dataStructure[node][indexQuestion].hasOwnProperty("answers")) {
        this.$set(this.dataStructure[node][indexQuestion], "answers", []);
      }
      this.dataStructure[node][indexQuestion]["answers"].push("option");
    },
    getRequirementData() {
      const requirementsKeys = [
        "sex",
        "age",
        "location",
        "questionsCapabilities",
      ];
      const self = this;
      Object.values(this.dataStructure).map((section) => {
        if (Array.isArray(section)) {
          section.map((element) => {
            requirementsKeys.map((key) => {
              if (element.inputType === key) {
                self.requirementData[key] = element;
              }
            });
          });
        }
      });
      this.requirementData.important = this.dataStructure.important;
      console.log("this", this.requirementData);
    },
    assingRequirementData() {
      const requirementsKeys = [
        "sex",
        "age",
        "location",
        "questionsCapabilities",
      ];
      const self = this;
      Object.values(this.dataStructure).map((section) => {
        if (Array.isArray(section)) {
          section.map((element) => {
            requirementsKeys.map((key) => {
              if (element.inputType === key) {
                element = self.requirementData[key];
              }
            });
          });
        }
      });
      this.dataStructure.important = this.requirementData.important;
    },
    addNewCapability() {
      this.requirementData.questionsCapabilities.answers.push({
        question: this.newCapability,
      });
      this.newCapability = "";
    },
    courseToShow() {
      if (this.wordForSearchCourse) {
        this.arrayCourseToShow = this.allGeneralCourses.filter((element) => {
          if (
            element.name.toLowerCase() ===
            this.wordForSearchCourse.toLowerCase()
          )
            return element;
        });
      } else {
        this.arrayCourseToShow = this.allGeneralCourses;
      }
    },
    async getStatesChange() {
      try {
        this.stateOptions = await locationService.getStates("Colombia");
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCitiesChange(country, state) {
      try {
        if ((country, state)) {
          this.citiesOptions = await locationService.getCities(country, state);
        } else {
          this.citiesOptions = await locationService.getCities(
            "Colombia",
            "Antioquia"
          );
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCommunes() {
      this.communeOptions = await locationService.getCommunes();
    },
  },
  created() {
    this.getCommunes();
    this.getStatesChange();
    this.getCitiesChange();
  },
  mounted() {
    this.getAllGeneralCourses();
  },
  beforeMount() {
    this.getCourseFormInformation();
  },
};
</script>

<style scoped lang="scss" src="./EditFormCourse.scss">

</style>
